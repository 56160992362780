import {memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-number-range.css'

//components
import { NumberBox } from 'devextreme-react/number-box';

import {
    Validator,
    RequiredRule,
    CustomRule
  } from 'devextreme-react/validator';

const CustomNumberRange = ({label, valueMin, valueMax, setValueMin, setValueMax,requiredValue,values,labelSize,isDisabled}) => {

  return (
    <div className='custom-number-range' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-number-range__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div className='custom-number-range__numbers'>
            <NumberBox
                  value={valueMin}
                  disabled={isDisabled}
                  valueChangeEvent="input"
                  placeholder='Min'
                  onValueChange={setValueMin}
                  min={1}
                  max={1000000}
            >
            {requiredValue &&
                <Validator>
                <RequiredRule message={`${label} is required`} />
                </Validator>
            }
            </NumberBox>
            <div className='custom-number-range__numbers__separator'></div>
            <NumberBox
                  value={valueMax}
                  disabled={isDisabled}
                  valueChangeEvent="input"
                  placeholder='Max'
                  onValueChange={setValueMax}
                  min={1}
                  max={1000000 }
            >
            {requiredValue &&
                <Validator>
                    <RequiredRule message={`${label} is required`} />
                </Validator>
            }
            <Validator>
                <CustomRule 
                    message={`Maximum budget must be greater than or equal to Minimum budget`}
                    validationCallback={()=>{
                        return valueMax >= valueMin || !valueMax
                    }}/>

            </Validator>
            </NumberBox>
        </div>
    </div>
  )
}

export default memo(CustomNumberRange)