import ActionButton from '../../assets/elements/button/action-button'
import CancelButton from '../../assets/elements/button/secondary-action-button'
import Loading from '../../assets/controls/loading'

const RequestPopupData = ({children,title, id, message, labelAction, labelCancel, action,closeAction,setVisible, disabled, isButtonGreen,buttonPaddingLeft}) => {
  return (
  <form onSubmit={e=>action(e,id)} className='request-properties-card-options__popup'>
      <div className='request-properties-card-options__popup__title'>{title} {id ? `#${id}`: ''}</div>
      <div className='request-properties-card-options__popup__message'>
          {message}
      </div> 
      {children}
      <div className='request-properties-card-options__popup__buttons' style={buttonPaddingLeft ? {paddingLeft: buttonPaddingLeft} : {justifyContent:'center'}}>
          <Loading isVisble={disabled} size={30}/>
          <div className={`request-properties-card-options__buttons__content__new ${isButtonGreen ? '' : 'btn-declined'}`}>
              <ActionButton disabled={disabled}>{labelAction}</ActionButton>
          </div>
          <CancelButton action={_ => closeAction ? closeAction() : setVisible(false)} disabled={disabled}>{labelCancel}</CancelButton>
      </div>
  </form>
  )
}

export default RequestPopupData