import { useState,useEffect,useRef } from 'react'

import {Link, useHref} from 'react-router-dom'

//Components
import TooltipIcon from '../elements/tooltip-icon'
import FormattedDate from '../elements/formatted-date'
import NotLoggedMessage from '../elements/not_logged_message'

import CustomDropdown from '../elements/input/custom-dropdown'
import FavoriteButton from '../elements/button/favorite-button'
import CustomPopup from '../elements/custom-popup'
import ActionButton from '../elements/button/action-button'
import CancelButton from '../elements/button/secondary-action-button'

//dev-extreme
import { Gallery } from 'devextreme-react/gallery';

//constant
import {AMENITIES_PARKING,AMENITIES_POOL} from '../../../constant/amenities'

//Images
import RenovatedImage from '../../../images/renovated.png'
import FloorImage from '../../../images/floor plan.png'
import LocationImage from '../../../images/location.png'
import RadiusImage from '../../../images/location_radius.png'
import PetAllowedImage from '../../../images/pets_friendly.png'
import PoolImage from '../../../images/swimmingpool.png'
import ParkingImage from '../../../images/parking.png'
import {ReactComponent as CartIcon} from '../../../images/cart-icon.svg'

//utils
import {isLoggedIn} from '../../../utils/user'
import {postPropertyToCart} from '../../../utils/property-card'


//styles
import '../styles/property-card.css'
import baseService from '../../../services/base.service'
import notify from 'devextreme/ui/notify';

const PropertyCard = ({ data, reference, isSelected, onHover, likeAProperty, unLikeAProperty, cardType, reverseType, addToCart, addToCartPopup, hideDistance, gallery }) => {
  const galleryRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [floor, setFloor] = useState('')
  const [floorPopup, setFloorPopup] = useState('')
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [resetWarningVisible,setResetWarningVisible] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [floorsDescription,setFloorsDescription] = useState('')
  const [amenitiesFound,setAmenitiesFound] = useState({
    pool: false,
    parking: false
  })
  const[isAddedToCart, setIsAddedToCart] = useState(false)
  const postAddToCartPopUp = _ =>{
    if(isLoggedIn())
    {
      setIsVisible(false)
        postPropertyToCart(floorPopup, data.id)
        setIsAddedToCart(true)
    }
    else{
      setResetWarningVisible(!resetWarningVisible)
      setIsWarningVisible(true)
    }
  }

  const postAddToCart = _ => {
    if(floor)
    {
      setIsEmpty(false)
      if(isLoggedIn())
      {
          postPropertyToCart(floor, data.id)
          setIsAddedToCart(true)
      }
      else{
        setResetWarningVisible(!resetWarningVisible)
        setIsWarningVisible(true)
      }
    }
    else{
      setIsEmpty(true)
    }
    }
    const deleteFomCart = async() => {
        let result = await deleteBasketProperty(data.id)
        setIsAddedToCart(false)
    }

    useEffect(() => {
        const checkIfaddedToCart = async (propertyId) => {
            if (propertyId) {
                let result = await checkIfInCart(propertyId)
                setIsAddedToCart(result !== "")
            }
        }
        if (isLoggedIn()) {
            checkIfaddedToCart(data.id);
          
        }

    }, [data.id]);

    const checkIfInCart = async (propertyId) => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart/check-if-added/${propertyId}`;
        return await baseService.get(url, {
            method: 'GET',
            withCredentials: false,
            headers: {
                'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
                'Content-Type': 'application/json',
            }
        }).then(({ data }) => {
            return data
        })
    }

    const handleGalleryClick = e => {
      if(e.target.closest('.dx-gallery-nav-button-prev,.dx-gallery-nav-button-next'))
        {
          e.preventDefault()
          e.stopPropagation()
        }
      
    }

    const deleteBasketProperty = async (id) => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart`;
        const formData = new FormData()
        formData.append('PropertyId', id)
        let response = await baseService.delete(url, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: formData
        })
        if (response?.data) {
           // setIsPropertyAddedToCart(false)
            notify('Removed from cart', "success", 3000);
        }
        else {
            notify('Something went wrong try again later', "error", 3000);
        }
        return response.data
    }
  useEffect(() => {
    let floors = []
    data.floorPlans.forEach(floor => floors.push(floor.name))
    if(floors.length > 0)
      setFloorsDescription(floors.join(','))
    else
      setFloorsDescription('All Floor Plans')

    let foundPool = data?.amenities?.some(amenity => AMENITIES_POOL.includes(amenity.id ))
    let foundParking = data?.amenities?.some(amenity => AMENITIES_PARKING.includes(amenity.id ))
    setAmenitiesFound({
      pool: foundPool,
      parking: foundParking
    })
  }, [data])
   useEffect(() => {
     if(galleryRef.current)
      {
        galleryRef.current.instance.repaint()
      }
   }, [cardType])
   
    return (
      <div style={{position:'relative',width: '100%'}} className='custom-card__wrapper'>
        <Link className={`${cardType === 'square' ? 'custom-card--square' : 'custom-card'} ${isSelected ? (cardType === 'square' ? 'custom-card--square--selected' : 'custom-card--selected') : ''} ${addToCart ? (cardType === 'square' ? 'custom-card--square--addToCart' : 'custom-card--addToCart') : ''} ${addToCartPopup ? (cardType === 'square' ? 'custom-card--square--addToCartPopup' : 'custom-card--addToCartPopup') : ''}`} ref={reference} to={`/property/dashboard/${data.metisId}`} onMouseEnter={onHover ? () => onHover(data.id) : () =>{}} target='_blank'>
          <div className='custom-card__image'>
            {
              gallery ?
                <div className='custom-card__image__wrapper' onClick={handleGalleryClick}>
                  <Gallery
                    ref={galleryRef}
                    id={`gallery${data.id}`}
                    dataSource={data.sliceImages}
                    loop={true}
                    showNavButtons={true}
                    showIndicator={true}
                  />
                </div>
              :
              <img src={data.imageUrl}
                style={{
                  backgroundImage: `url(${data.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  textIndent: '-10000px'
                }}
                // height={90} width={135}
              />
            }
            
          </div>
          {/* <div className='custom-card__title' style={data.metisId !== data.id ? {color: 'red'} : {color: 'green'}}><span>{`Property #${data.metisId}`}</span>{cardType !== 'square' && <span>{data.recentlyRenovated && <TooltipIcon id={`renovated_${data.id}`} image={RenovatedImage} label={<FormattedDate fdate={data.dateOfRenovation}/>}/>}</span>}</div> */}
          <div className='custom-card__title'><span>{`Property #${data.metisId}`}</span>{cardType !== 'square' && <span>{data.recentlyRenovated && <TooltipIcon id={`renovated_${data.id}`} image={RenovatedImage} label={<FormattedDate fdate={data.dateOfRenovation}/>}/>}</span>}</div>
          <div className='custom-card__description'>
              {cardType !== 'square' && <div className='custom-card__description__location'><img src={LocationImage} alt='location img'/><div className='custom-card__description__location__text'>{data.formattedAddress.replace(/,\s*,/g, ', ').replace(/^,/, '')}</div></div>}
              {/* {cardType !== 'square' && <div className='custom-card__description__location'><img src={LocationImage} alt='location img'/><div className='custom-card__description__location__text'>{data.formattedAddress.replace(/\s+/g,'').replace(/,{2,}/g,',')}</div></div>} */}
              {!hideDistance && <div className='custom-card__description__radius'><img src={RadiusImage} alt='radius img' width='17px' height='17px'/><span className='custom-card__description__radius__text'>{cardType === 'square' ? 'Distance: ': ''}{data.distanceInMiles} Miles.</span></div>}
              {/* {cardType !== 'square' && <div><img src={FloorImage} alt='floor img' width='17px' height='17px'/>{floorsDescription}</div>}            */}
          </div>
          <div className='custom-card__floorplans'><img src={FloorImage} alt='floor img'/><div className='custom-card__floorplans__text'>{floorsDescription}</div></div>          
          <div className={`custom-card__includes ${reverseType ? 'custom-card__includes--reverse' : ''}`}>
            {(amenitiesFound.pool || amenitiesFound.parking || data.petsAllowed) &&
              <div className='custom-card__includes__amenities'>
                {amenitiesFound.pool && <TooltipIcon id={`pool_${data.id}`} image={PoolImage} label={'Swimming Pool'}/>}
                {amenitiesFound.parking && <TooltipIcon id={`parking_${data.id}`} image={ParkingImage} label={'Parking'}/>}
                {data.petsAllowed && <TooltipIcon id={`petAllowed_${data.id}`} image={PetAllowedImage} label={'Pets Friendly'}/>}
              </div>
            }
              <div className='custom-card__includes__building-type'>{data.buildingTypeName}</div>
          </div>
          {
            addToCart && 
            <div className='custom-card__addToCart' onClick={e=>{
              e.preventDefault()
              e.stopPropagation()
              }}>
              <div className='custom-card__addToCart__floor'>
                  <div className='custom-card__addToCart__floor__image'>
                    <img src={FloorImage} alt=""/>
                  </div>
                  <div className='custom-card__addToCart__floor__dropdown__container'>
                    <div className='custom-card__addToCart__floor__dropdown__container__group'>
                      <div style={{width:'100%'}}>
                        <CustomDropdown isEmpty={isEmpty} setIsEmpty={setIsEmpty} values={data.floorPlans} value={floor} setValue={setFloor} requiredValue={true} placeholder='Floor Plan'/>
                                        </div>
                                        {
                                            !isAddedToCart ? 
                                                <div className='custom-card__addToCart__floor__button' onClick={postAddToCart}>Add to Cart for Quotes</div>
                                                :
                                                <div className='custom-card__addToCart__floor__button button__red' onClick={deleteFomCart}>Remove from cart</div>

                                        }
                     
                    </div>
                    <span className='custom-card__addToCart__floor__dropdown__container__message'>* Choose floor plan to add option to cart</span>
                  </div>
              </div>
            </div>
          }
          {
            addToCartPopup && 
            <div className='custom-card__addToCartPopup' onClick={e=>{
              e.preventDefault()
              e.stopPropagation()
                        }}>
                            {
                                !isAddedToCart ?
                                    <div className='custom-card__addToCart__floor__button' onClick={_ => setIsVisible(true)}><span>Add to Cart for Quotes</span> <CartIcon /> </div>
                                    :
                                    <div className='custom-card__addToCart__floor__button button__red' onClick={deleteFomCart}>Remove from cart</div>

                            }
              {/*<div className='custom-card__addToCart__floor__button' onClick={_=>setIsVisible(true)}><span>Add to Cart</span> <CartIcon/> </div>*/}
              
            </div>
          }
          <div className='custom-card__favorite'>
          {cardType === 'square' && <span>{data.recentlyRenovated && <TooltipIcon id={`renovated_${data.id}`} image={RenovatedImage} label={<FormattedDate fdate={data.dateOfRenovation}/>}/>}</span>}
            <FavoriteButton key={data.id} metisId={data.metisId} propertyId={data.id} isLiked={data.isLiked} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty} cardType={cardType}/>
          </div>
        </Link> 
        <CustomPopup visible={isVisible} onHiding={_=>setIsVisible(false)}>
            <div className='custom-card__modal'>
              <div className='custom-card__modal__title'>Add Property #{data.metisId} to the cart</div>
              <div className='custom-card__modal__message'>To add Property #{data.metisId} to the cart, please choose the floor plan you are interested in:</div>
              <div className='custom-card__modal__dropdown'>
                <CustomDropdown values={data.floorPlans} value={floorPopup} setValue={setFloorPopup} placeholder='Floor Plan'/>
              </div>
              <div className='custom-card__modal__buttons'>
                  <ActionButton disabled={floorPopup.length === 0} action={postAddToCartPopUp}>Add to Cart for Quotes</ActionButton>
                  <CancelButton action={_=>setIsVisible(false)}>Cancel</CancelButton>
              </div>
            </div>            
        </CustomPopup>
        <NotLoggedMessage 
          message='to add property to the cart'
          visible={isWarningVisible}
          setVisible={setIsWarningVisible}
          resetTrigger={resetWarningVisible}
        />
      </div>
        
  )
}

export default PropertyCard