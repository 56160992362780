export const SET_CURRENCY_TYPES = "SET_CURRENCY_TYPES";
export const SET_FLOOR_PLAN_TYPES = "SET_FLOOR_PLAN_TYPES";
export const SET_AMENITIES = "SET_AMENITIES";
export const SET_ALL_AMENITIES = "SET_ALL_AMENITIES";

export const SET_DICT_BUILDING_TYPES = "SET_DICT_BUILDING_TYPES";
export const SQUARE_FOOTAGE = [
    {id: 2, name: 'ft'},
    {id: 1, name: 'm'}
]
export const WEIGHT = [
    {id: 2, name: 'lb'},
    {id: 1, name: 'kg'}
]
export const DEFAULT_CURRENCY = 150
export const MONTH_NAMES = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

export const GET_REQUEST_STATUS = {
    "New": 1,
    "Open": 2,
    "Quoted": 3,
    "Pending_Reservation": 4,
    "Reservation_Created": 5,
    "Pending_Booking": 6,
    "Booked":7,
    "Not_Booked": 8,
    "Expired": 9,
    "Declined": 10,
    "Pending_Extension": 11,
    "Extended": 12,
    "Pending_Termination": 13,
    "Terminated": 14,
    "Completed": 15,
    "Cancelled": 16,
    "Pending_Booking_Cancellation":17,
    "Cancelled_by_Manager": 18,
    "ExtesionRequested": 19,
}

export const SET_REQUEST_STATUS = [
    {
        id: 1,
        name: 'New'
    },
    {
        id: 2,
        name: 'Open'
    },
    {
        id: 3,
        name: 'Quoted'
    },
    {
        id: 4,
        name: 'Pending Reservation'
    },
    {
        id: 5,
        name: 'Reservation Created'
    },
    {
        id: 6,
        name: 'Pending Booking'
    },
    {
        id: 7,
        name: 'Booked'
    },
    {
        id: 8,
        name: 'Not Booked'
    },
    {
        id: 9,
        name: 'Expired' 
    },
    {
        id: 10,
        name: 'Declined'
    },
    {
        id: 11,
        name: 'Pending Extension'
    },
    {
        id: 12,
        name: 'Extended'
    },
    {
        id: 13,
        name: 'Pending Termination'
    },
    {
        id: 14,
        name: 'Terminated'
    },
    {
        id: 15,
        name: 'Completed'
    },
    {
        id: 16,
        name: 'Cancelled'
    },
    {
        id: 17,
        name: 'Pending Booking Cancellation'
    },
    {
        id: 18,
        name: 'Cancelled by Manager'
    },
    {
        id: 19,
        name: 'Extension Requested'
    }
]

export const REASONS_DECLINE = [
    {
        id: 1,
        name: 'Rate is too high'
    },
    {
        id: 2,
        name: 'Distance is too far'
    },
    {
        id: 3,
        name: 'Unit size is too small'
    },
    {
        id: 4,
        name: 'Date available is incorrect'
    },
    {
        id: 5,
        name: 'Other reason'
    }
]

export const MAID_SERVICE = [
    {
        id: 1,
        name: 'None'
    },
    {
        id: 2,
        name: 'Daily'
    },
    {
        id: 3,
        name: 'TwiceWeekly'
    },
    {
        id: 4,
        name: 'Weekly'
    },
    {
        id: 5,
        name: 'EveryOtherWeek'
    },
    {
        id: 6,
        name: 'Monthly'
    },
    {
        id: 7,
        name: 'EveryOtherMonth'
    },
    {
        id: 8,
        name: 'Quarterly'
    },
    {
        id: 9,
        name: 'EverySixMonths'
    },
    {
        id: 10,
        name: 'OnceAYear'
    },
    {
        id: 11,
        name: 'AsNeeded'
    }
]

export const PARKING_SLOTS = [
    {
        id: 1,
        name: '1'
    },
    {
        id: 2,
        name: '2'
    },
    {
        id: 3,
        name: '3'
    },
]

export const EXCLUDED_CITIES = [
 "tokyo",
 "singapore",
 "sanmarino"
]

export const EXCLUDED_CITIES_DETAILS = {
    "tokyo": {
        "northeast": {
          "lat": 35.8174453,
          "lng": 139.9188743
        },
        "southwest": {
          "lat": 35.519042,
          "lng": 139.5628611
        }
      },
    "sanmarino": {
        "northeast": {
          "lat": 43.9403789,
          "lng": 12.4529661
        },
        "southwest": {
          "lat": 43.9242767,
          "lng": 12.4383433
        }
      },
    "singapore":{
        "northeast": {
          "lat": 1.4784001,
          "lng": 104.0945001
        },
        "southwest": {
          "lat": 1.1496,
          "lng": 103.594
        }
      }
}