import {useState} from 'react'

//components
import CustomPopup from '../../assets/elements/custom-popup'
import CustomConfirmationPopup from '../../assets/controls/custom-confirmation-popup'
import Tooltip from '../../assets/elements/tooltip-icon'


//images
import BuildingIcon from '../../../images/request-icons/building.svg'
import CalendarIcon from '../../../images/request-icons/calendar.svg'
import FloorPlanIcon from '../../../images/request-icons/floor_plan.svg'
import LocationIcon from '../../../images/request-icons/location.svg'
import MoneyIcon from '../../../images/request-icons/money.svg'
import PetIcon from '../../../images/request-icons/pet.svg'
import UserIcon from '../../../images/request-icons/user.svg'
import ChildIcon from '../../../images/request-icons/child.svg'
import ViewIcon from '../../../images/admin-icons/eye-icon.svg'
import EditIcon from '../../../images/admin-icons/pencil-icon.svg'
import {ReactComponent as DeleteIcon} from '../../../images/admin-icons/trash-icon.svg'

//utils
import {putCancelRequest} from '../../../utils/my-requests'

//styles
import '../styles/request-requirements-card.css'
const RequestRequirementsCard = ({ requirements, requestId,hiddeActions,mutedActions, mutedMessage}) => {
    const [visibleCancel, setVisibleCancel] = useState(false)
    const [showAmenities, setShowAmenities] = useState(false)
    const cancelRequest = async ()  => {
        putCancelRequest(requestId)
        setVisibleCancel(false)
    }

  return (
    <div className='request-requirements-card'>
        <div className='request-requirements-card__content'>
            <div className='request-requirements-card__content__section1'>
                    <div className='request-requirements-card__address'><img src={LocationIcon}/><span>{requirements.address.replace(/,\s*,/g, ', ').replace(/^,/, '')}</span></div>
                    {/* <div className='request-requirements-card__address'><img src={LocationIcon}/><span>{requirements.address.replace(/\s+/g,'').replace(/,{2,}/g,',')}</span></div> */}
                    <div className='request-requirements-card__floor'><img src={FloorPlanIcon}/><span>{requirements.floorPlan}</span></div>
                    <div className='request-requirements-card__date'><img src={CalendarIcon}/><span>{requirements.formattedDate}</span></div>
            </div>
            <div className='request-requirements-card__content__section2'>
                    {requirements.budget && <div className='request-requirements-card__budget'><img src={MoneyIcon}/><span>{requirements.budget}</span></div>}
                    {requirements.buildings && <div className='request-requirements-card__building'><img src={BuildingIcon}/><span>{requirements.buildings}</span></div>}
                    <div className='request-requirements-card__qty'>
                        <div className='request-requirements-card__qty__set'><img src={UserIcon}/><span>{requirements.adults}</span></div>
                        <div className='request-requirements-card__qty__set'><img src={ChildIcon}/><span>{requirements.children}</span></div>
                        <div className='request-requirements-card__qty__set'>{requirements.petDetails ? <Tooltip isHtml={true} id={`pet_${requestId}`} image={PetIcon} label={requirements.petDetails}/> : <img src={PetIcon}/>}<span>{requirements.pets}</span></div>
                    </div>
            </div>
            <div className='request-requirements-card__content__section3'>
                {requirements.amenities && <div className='request-requirements-card__amenities'><b>Required amenities:</b> {requirements.amenities} {requirements.allAmenities ? <span>{showAmenities ? ', ':'... '}</span> : '.'} {(requirements.allAmenities && showAmenities) && <span>{requirements.allAmenities}.</span>} {requirements.allAmenities && <span className='request-requirements-card__showAmenities' onClick={_=>setShowAmenities(!showAmenities)}>{showAmenities ? 'Show Less' : 'Show More'}</span>}</div>}
                {requirements.notes && <div className='request-requirements-card__note'><b>Notes:</b> {requirements.notes}</div>}
            </div>
        </div>
        <div className='request-requirements-card__buttons'>
            <div className='request-requirements-card__buttons__container'>
                {!hiddeActions && <div className='request-requirements-card__button__edit'><img src={EditIcon} /><span>Edit</span></div>}
                {!hiddeActions && 
                    <div className='request-requirements-card__button__group'>
                        <button type='button' className={`request-requirements-card__button__annul ${mutedActions ? 'request-requirements-card__button__annul__disabled' : ''}`} disabled={mutedActions} onClick={_=>setVisibleCancel(true)}><DeleteIcon /><span>Cancel Request</span></button>
                        {mutedActions && <div className='request-requirements-card__button__group__muted-message'><span>*</span>{mutedMessage}</div>}
                    </div>
                }
                {/* <div className='request-requirements-card__button__edit'><img src={EditIcon} /><span>Edit</span></div>
                
                    <div className='request-requirements-card__button__group'>
                        <button type='button' className={`request-requirements-card__button__annul ${mutedActions ? 'request-requirements-card__button__annul__disabled' : ''}`} onClick={_=>setVisibleCancel(true)}><DeleteIcon /><span>Cancel Request</span></button>
                        {mutedActions && <div className='request-requirements-card__button__group__muted-message'><span>*</span>-You have Active Reservation, cancel it before Cancel Request</div>}
                    </div> */}
                
            </div>
        </div>
        <div className='request-requirements-card__divisor'></div>
        <CustomPopup visible={visibleCancel} onHiding={_=>setVisibleCancel(false)}>
            <CustomConfirmationPopup 
                titleConfirm='CANCEL REQUEST' 
                labelConfirm='Cancel' 
                onHiding={_=>setVisibleCancel(false)} 
                onConfirm={cancelRequest}
                message={`Are you sure you want to Cancel the Request #${requestId}`}
            />
        </CustomPopup>
    </div>
  )
}

export default RequestRequirementsCard