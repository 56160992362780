import baseService from "../services/base.service";
import store from '../store'
import moment from "moment"
//files
// import { getPropertyImage } from './files'

//utils
import {isLoggedIn,getUserId} from './user'
import {getListGuests} from './user-form'

//actions
import {setLoading,setSelectedGuestId,setDefaultQuickRequest} from '../actions/quick-request'
import {setBasketTimer} from '../actions/my-basket'


//constant
import { BUILDING_TYPES } from '../constant/building-types'

//devextreme
import notify from 'devextreme/ui/notify';


const getGuestId = async (fields,userId) => {
      let guestId = ''
      if(fields.isGuestCheckBoxSelected)
      {
        if(fields.isExistingUser)
          guestId = fields.guestUser.id
        else
        {
          let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/guestContact/AddGuestContact`
          let data = new URLSearchParams()
          data.append('UserProfileId',userId)
          data.append('Email',fields.guestEmail)
          data.append('FirstName',fields.guestUser.firstName)
          data.append('LastName',fields.guestUser.lastName)
          data.append('PhoneNumber',fields.guestPhone)
          let response = await baseService.post(url,data, {
            headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
            },            
          }).then(data=> data).catch(error => error)
          if(response.status === 200)
          {
            getListGuests()
            store.dispatch(setSelectedGuestId(response.data.key[0]))
            return {
              status: true,
              id: response.data.key[0]
            }
          }
          else{
            return {
              status: false,
              message: response.response.data
            }
          }
        }
      }
      return {
        status: true,
        id: guestId
      }
}

const postSendRequest = async (address) => {
  try {
    if(isLoggedIn())
    {
      if(store.getState().basket.basketTimer === null || (Date.now() - store.getState().basket.basketTimer) > 60000){

        store.dispatch(setLoading(true))
        let userId = getUserId()
        let quickRequestState = store.getState().quickRequest
        let fields = quickRequestState.fields

        let responseGuest = await getGuestId(fields,userId)
        if(responseGuest.status)
        {

            let amenitiesId = fields.selectedDataAmenities.map(amenity => amenity.id)
            
            let formattedPetDetails = fields.petDetails.map(item => ({
              "kindPet": item.petType,
              "weight": item.petWeight,
              "petWeightType": item.petWeightType.id
            }))

            let formattedBuildingTypes
            if(fields.buildingTypes.includes('all'))
              formattedBuildingTypes = BUILDING_TYPES.map(item => item.id)
            else
                formattedBuildingTypes = fields.buildingTypes

            let moveInCleanUTC = moment(fields.dateRange[0]).format("YYYY-MM-DD")
            let moveOutCleanUTC = moment(fields.dateRange[1]).format("YYYY-MM-DD")

            let data = {
              "addedById": userId,
              "guestContactId": responseGuest.id,
              "moveIn": moment.utc(moveInCleanUTC).toISOString(),  
              "moveOut": moment.utc(moveOutCleanUTC).toISOString(),
              "address": address,
              "formattedAddress": address.formattedAddress,
              "radius": fields.miles,
              "nightlyBudgetMin": typeof fields.budgetMin === 'number' ? fields.budgetMin : null,
              "nightlyBudgetMax": fields.budgetMax ? fields.budgetMax : null,
              "currencyId": fields.currencyType ? fields.currencyType : 0,
              "buildingTypes": formattedBuildingTypes,
              "floorPlanTypes": fields.floorPlan ? fields.floorPlan : [],
              "totalAdults": fields.qtyAdults ? fields.qtyAdults : 0,
              "totalChildren": fields.qtyChildren ? fields.qtyChildren : 0,
              "petsAllowed": fields.isPets,
              "petsAmount": (fields.qtyPets &&  fields.qtyPets > 0) ? fields.qtyPets : null,
              "pets": formattedPetDetails.length > 0 ? formattedPetDetails : null,
              "notes": fields.notes,
              "amenities": amenitiesId,
              "maidServiceFrequency": fields.maidService ? fields.maidService : 0,
              "parkingRequested": fields.parkingSlots ? true : false,
              "parkingSpacesRequested": fields.parkingSlots ? fields.parkingSlots : null
            }
            if(!data.guestContactId)
            {
              const {guestContactId,...rest} = data
              data =  rest
            }
            
            if(!data.petsAllowed)
            {
              const {petsAmount,pets,...rest} = data
              data =  rest
            }
            const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/create-request`;
            let isResponseOk = baseService.post(url,JSON.stringify(data), {
              headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json'
              },            
            })
            .then(data=> {
              if(data.status === 200)
              {
                store.dispatch(setDefaultQuickRequest())
                notify('The request was sent successfully', "success" , 3000);
                return true
              }
              else{
                store.dispatch(setLoading(false))
                notify('Something went wrong try again later','error',3000)
                return false
              }
            })
            .finally(_=>{
              store.dispatch(setBasketTimer(Date.now()))
            })
            .catch(error => {
              store.dispatch(setLoading(false))
              notify(error.response.data,'error',3000)
              return false
            })
            return isResponseOk
        }
        else{
          store.dispatch(setLoading(false))
          notify(responseGuest.message,'error',3000)
          return false
        }
      }
      else{
        notify('API calls quota exceeded! maximum admitted 1 per 1m.','error',3000)
      }

    }
    else{
      store.dispatch(setLoading(false))
      notify('You need to be logged in', "error" , 3000);
      return false
    }
  } catch (error) {
    store.dispatch(setLoading(false))
    notify(`Something went wrong try again later`, "error" , 3000);
    return false
  }
}

export { postSendRequest}