import baseService from "../services/base.service";
import store from '../store'
import moment from 'moment'
//files
// import { getPropertyImage } from './files'

//utils
import {isLoggedIn,getUserId} from './user'
import {getListGuests} from './user-form'

//actions
import {setBasket,setLoading,setAllBasket,setSelectedGuestId,setLoadingSendRequest,setBasketDefault,setNoMatch,setBasketTimer,setDataToCheck} from '../actions/my-basket'

//constant
import { BUILDING_TYPES } from '../constant/building-types'

//devextreme
import notify from 'devextreme/ui/notify';

const getValidAddresses = async (data,latitude,longitude,radius) => {
  let propertyIdsToCheck = '';
  data.forEach(p => propertyIdsToCheck += (`&propertyIds=${p.propertyId}`));
  let validAddresses = null;
  if (latitude && longitude && propertyIdsToCheck.length > 0 && radius) {
      let result = await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart/validate?latitude=${latitude}&longitude=${longitude}&radius=${radius}${propertyIdsToCheck}&skip=0&take=1000`)
      validAddresses = result.data;
  }
  return validAddresses
}
const setDataToCheckDefault = _ => {
  store.dispatch(setDataToCheck({}))
  store.dispatch(setNoMatch(false))
}
const getBasketPerPage = async (skip,dataToCheck) => {
  try {
    if(isLoggedIn())
    {

      store.dispatch(setLoading(true))

      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart?skip=${skip}&take=20&requireTotalCount=true`;
      let response = await baseService.get(url)
        const { data: { data } } = response;

        let validAddresses = await getValidAddresses(data,dataToCheck.latitude,dataToCheck.longitude,dataToCheck.radius)

        let formattedData = formatArrayWithNoMatchProperties(dataToCheck.propertyType, dataToCheck.floor, dataToCheck.city, dataToCheck.pet, data, validAddresses)
      
      store.dispatch(setBasket({
        totalCount: response.data.totalCount,
        data: formattedData
      }))
      store.dispatch(setLoading(false))
    }
    else{
      store.dispatch(setLoading(false))

      return []
    }
  } catch (error) {
    store.dispatch(setLoading(false))

    return []

  }
}

const getAllBasket = async (validateMatch) => {
  try {
    if(isLoggedIn())
    {

      let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart?skip=0&take=1&requireTotalCount=true`;
     
      let responseTotal = await baseService.get(url, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart?skip=0&take=${responseTotal.data.totalCount}&requireTotalCount=true`;
     
      let response = await baseService.get(url, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      
      store.dispatch(setAllBasket({
        totalCount: response.data.totalCount,
        data: response.data.data
      }))
      if(validateMatch)
        getPropertiesNoMatch()
    }
    else{
      store.dispatch(setAllBasket({
        totalCount: 0,
        data: []
      }))
    }
  } catch (error) {
    store.dispatch(setAllBasket({
      totalCount: 0,
      data: []
    }))
  }
}

const deleteBasketProperty = async (propertyId) => {
  try {
    if(isLoggedIn())
    {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart`;
      const formData = new FormData()
      formData.append('PropertyId',propertyId)
      let response = await baseService.delete(url, {
        headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
      })
      if(response?.data)
      {
        if(store.getState().basket.noMatch)
          getAllBasket(true)
        else
          getAllBasket()
      }
      else{
        notify('Something went wrong try again later', "error" , 3000);
      }
      return response.data
    }
    else{
      notify('You need to be logged in', "error" , 3000);
      return false
    }
  } catch (error) {
    return false
    // store.dispatch(setRecommendedPropertiesData([]))
    // store.dispatch(setLoadingRecommendedProperties(false))
  }

}

const getGuestId = async (fields,userId) => {
      let guestId = ''
      if(fields.isGuestCheckBoxSelected)
      {
        if(fields.isExistingUser)
          guestId = fields.guestUser.id
        else
        {
          let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/guestContact/AddGuestContact`
          let data = new URLSearchParams()
          data.append('UserProfileId',userId)
          data.append('Email',fields.guestEmail)
          data.append('FirstName',fields.guestUser.firstName)
          data.append('LastName',fields.guestUser.lastName)
          data.append('PhoneNumber',fields.guestPhone)
          let response = await baseService.post(url,data, {
            headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
            },            
          }).then(data=> data).catch(error => error)
          if(response.status === 200)
          {
            // store.dispatch(setIsExistingUser(true))
            // store.dispatch(setGuestUser({
            //   ...fields.guestUser,
            //   id: response.data.key[0]
            // }))
            getListGuests()
            store.dispatch(setSelectedGuestId(response.data.key[0]))
            return {
              status: true,
              id: response.data.key[0]
            }
          }
          else{
            return {
              status: false,
              message: response.response.data
            }
          }
        }
      }
      return {
        status: true,
        id: guestId
      }
}

const combinedBuildingType = (typeId, allBuildingTypes) => {
    let propertyBuildingType = allBuildingTypes.find(b => b.id === typeId);
    switch (propertyBuildingType.name) {
        case "Hotel":
            return 1
        case "Corporate (Serviced) Apartment":
        case "Apartment":
            return 2
        case "Single Family Home":
            return 4
        case "Villa":
        case "Town Home":
            return 3
        case "OC Exclusive Property":
            return 10
        default:
            return 0
    }
}

const formatArrayWithNoMatchProperties = (propertyType, floor, city, pet, allProperties, validAddresses) => {
  let notMatch = false
  let formattedData = allProperties.map(item => {
    
    let coincidences = []

      let floorPlansIds = item.supplierProperty.floorPlans.map(floor => floor.id)
      let allBuildingTypes = store.getState().dictionary.dictBuildingTypes;  
      const transformedBuildingType = combinedBuildingType(item.supplierProperty.buildingTypeId, allBuildingTypes)

    if (propertyType?.length > 0 && !propertyType.includes(transformedBuildingType) )
     {  
        notMatch = true
       coincidences.push('Type of Property')
     }

    if(floor?.length > 0)
    {
        let floorFounded = floor.filter(value => floorPlansIds.includes(value))
        if(floorFounded.length <= 0)
        {
          notMatch = true
          coincidences.push('Floor Plan')
        }
    }
    
    // if(floor && !floorPlansIds.includes(floor))
    //   coincidences.push('Floor Plan')
      //if(city && city.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC') !== item.supplierProperty.formattedAddress.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC'))
      const isAddressValid = validAddresses ? validAddresses.find(a => a.propertyId == item.propertyId) : null;
      if (validAddresses && !isAddressValid) {
          notMatch = true
          coincidences.push('Address')
      }
    if(pet && item.supplierProperty.petsAllowed !== pet)
    {
      notMatch = true
      coincidences.push('Pets')
    }
      
    

    return {
      ...item.supplierProperty,
      selectedFloorPlans: item.floorPlanTypes,
      isMuted: coincidences.length > 0,
      notMatch: coincidences
    }
  })
  store.dispatch(setNoMatch(notMatch))

  return formattedData
}

const separateProperties = async (propertyType,floor,city,pet,allProperties,validAddresses) => {
  let arrays = {
    favorites : [],
    preferredProperties: []
  }
  let formattedData = formatArrayWithNoMatchProperties(propertyType,floor,city,pet,allProperties,validAddresses)
  arrays.favorites = formattedData.filter(item => item.isMuted === true).map(item => item.id)
  arrays.preferredProperties = formattedData.filter(item => item.isMuted === false)
  return arrays
}

const cartToFavorites = async (ids) => {
  try {
    if(isLoggedIn())
    {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart/MoveToLikedProperties`;
      const formData = new FormData()
      ids.forEach(item => formData.append('Properties',item))

      let response = await baseService.delete(url, {
        headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
      })
      if(response)
      {
        if(response.status === 200)
          return true
        else
          return false
      }
      else{
        notify('Something went wrong try again later', "error" , 3000);
        return false
      }
    }
    else{
      notify('You need to be logged in', "error" , 3000);
      return false
    }
  } catch (error) {
    notify('Something went wrong try again later', "error" , 3000);
    return false
  }
}

const postSendRequest = async (address) => {
  try {
    if(isLoggedIn())
    {
      if(store.getState().basket.basketTimer === null || (Date.now() - store.getState().basket.basketTimer) > 60000){
        store.dispatch(setLoadingSendRequest(true))
        let userId = getUserId()
        let basketState = store.getState().basket
        let fields = basketState.fields
        
        let responseGuest = await getGuestId(fields,userId)
        if(responseGuest.status)
        {
            let cityName = address.city
            let amenitiesId = fields.selectedDataAmenities.map(amenity => amenity.id)
            let formattedPetDetails = fields.petDetails.map(item => ({
              "kindPet": item.petType,
              "weight": item.petWeight,
              "petWeightType": item.petWeightType.id
            }))

            let validAddresses = await getValidAddresses(basketState.allBasket.data,address.latitude,address.longitude,fields.miles)

            let separateArrays = await separateProperties(fields.buildingTypes,fields.floorPlan,cityName,fields.isPets,basketState.allBasket.data,validAddresses)
            
              let preferredProperties = separateArrays.preferredProperties.map(property => ({
                "propertyId": property.id,
                "floorPlanId": property.selectedFloorPlans[0].floorPlanId
              }))
              let formattedBuildingTypes
              if(fields.buildingTypes.includes('all'))
                formattedBuildingTypes = BUILDING_TYPES.map(item => item.id)
              else
                formattedBuildingTypes = fields.buildingTypes

            let moveInCleanUTC = moment(fields.dateRange[0]).format("YYYY-MM-DD")
            let moveOutCleanUTC = moment(fields.dateRange[1]).format("YYYY-MM-DD")
              let data = {
                "addedById": userId,
                "guestContactId": responseGuest.id,
                "moveIn": moment.utc(moveInCleanUTC).toISOString(),
                "moveOut": moment.utc(moveOutCleanUTC).toISOString(),
                "address": address,
                "formattedAddress": address.formattedAddress,
                "radius": fields.miles,
                "nightlyBudgetMin": typeof fields.budgetMin === 'number' ? fields.budgetMin : null,
                "nightlyBudgetMax": fields.budgetMax ? fields.budgetMax : null,
                "currencyId": fields.currencyType ? fields.currencyType : 0,
                "buildingTypes": formattedBuildingTypes,
                "floorPlanTypes": fields.floorPlan ? fields.floorPlan : [],
                "totalAdults": fields.qtyAdults ? fields.qtyAdults : 0,
                "totalChildren": fields.qtyChildren ? fields.qtyChildren : 0,
                "petsAllowed": fields.isPets,
                "petsAmount": (fields.qtyPets &&  fields.qtyPets > 0) ? fields.qtyPets : null,
                "pets": formattedPetDetails.length > 0 ? formattedPetDetails : null,
                "notes": fields.notes,
                "amenities": amenitiesId,
                "preferredProperties": preferredProperties,
                "maidServiceFrequency": fields.maidService ? fields.maidService : 0,
                "parkingRequested": fields.parkingSlots ? true : false,
                "parkingSpacesRequested": fields.parkingSlots ? fields.parkingSlots : null
              }
              if(!data.guestContactId)
              {
                const {guestContactId,...rest} = data
                data =  rest
              }
              if(data.preferredProperties.length <= 0)
              {
                const {preferredProperties,...rest} = data
                data =  rest
              }
              if(!data.petsAllowed)
              {
                const {petsAmount,pets,...rest} = data
                data =  rest
              }

              const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/create-request`;
              let isResponseOk = await baseService.post(url,JSON.stringify(data), {
                headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
                  },
                  transactionIntegration: false
                  // additional options for managing the load indicator
                  //closeLoaderAfter: true,
                  //showLoader: true                
              })
              .then(data => {
                if(data.status === 200)
                {
                  store.dispatch(setBasketDefault())
                  notify('The request was sent successfully', "success" , 3000);
                  return true
                }
                else{
                  store.dispatch(setLoadingSendRequest(false))
                  notify('Something went wrong try again later','error',3000)
                  return false
                }
              })
              .finally(_=>{
                store.dispatch(setBasketTimer(Date.now()))
              })
              .catch(error => {
                store.dispatch(setLoadingSendRequest(false))
                notify(error.response.data,'error',3000)
                return false
              })
              
              if(isResponseOk)
              {
                let wasPropertiesMoved = separateArrays.favorites.length > 0 ? await cartToFavorites(separateArrays.favorites) : true
                if(!wasPropertiesMoved)
                {
                  notify('Error: Some properties could not be moved to favorites','error',3000)
                  return false
                }
              }
              return isResponseOk
            
        }
        else{
          store.dispatch(setLoadingSendRequest(false))
          notify(responseGuest.message,'error',3000)
          return false
        }
      }
      else{
        notify('API calls quota exceeded! maximum admitted 1 per 1m.','error',3000)
      }
     
    }
    else{
      store.dispatch(setLoadingSendRequest(false))
      notify('You need to be logged in', "error" , 3000);
      return false
    }
  } catch (error) {
    store.dispatch(setLoadingSendRequest(false))
    notify(`Something went wrong try again later`, "error" , 3000);
    return false
  }
}

const getPropertiesNoMatch = async _ => {
  try {
    if(isLoggedIn())
    {
             

      let allBasket =  store.getState().basket.allBasket
      let dataToCheck = store.getState().basket.dataToCheck
      let validAddresses = await getValidAddresses(allBasket.data,dataToCheck.latitude,dataToCheck.longitude,dataToCheck.radius)
      let notMatch = false
      allBasket.data.forEach(item => {
        let floorPlansIds = item.supplierProperty.floorPlans.map(floor => floor.id)

       let allBuildingTypes = store.getState().dictionary.dictBuildingTypes;  
       const transformedBuildingType = combinedBuildingType(item.supplierProperty.buildingTypeId, allBuildingTypes)
        if (dataToCheck.propertyType?.length > 0 && !dataToCheck.propertyType.includes(transformedBuildingType) )
        {
          notMatch = true
        }

        if(dataToCheck?.floor?.length > 0)
        {
          let floorFounded = dataToCheck.floor.filter(value => floorPlansIds.includes(value))
          if(floorFounded.length <= 0)
            notMatch = true   
        }
        
        
        const isAddressValid = validAddresses ? validAddresses.find(a => a.propertyId == item.propertyId) : null;
        if (validAddresses && !isAddressValid) {
            notMatch = true
        }
        // if(dataToCheck.city && dataToCheck.city.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC') !== item.supplierProperty.formattedAddress.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC'))
        //   {
        //     notMatch = true
        //   }
        if(dataToCheck.pet && item.supplierProperty.petsAllowed !== dataToCheck.pet)
          {
            notMatch = true
          }
      })
      store.dispatch(setNoMatch(notMatch))
    }
    else{
      notify('You need to be logged in', "error" , 3000);
    }
  }
  catch (error) {
    notify('Something went wrong try again later', "error" , 3000);
  }
}

export { getBasketPerPage,deleteBasketProperty,getAllBasket,postSendRequest,getPropertiesNoMatch,setDataToCheckDefault}