import {useState,useEffect} from 'react'
import {connect} from 'react-redux'
import {debounce} from 'lodash'

//components
import Loading from '../../assets/controls/loading'
import ActionButton from '../../assets/elements/button/action-button'
import Location from '../../assets/elements/input/search-location-alt'
import GoogleWrapper from '../../assets/controls/google-wrapper'
import CustomDropdown from '../../assets/elements/input/custom-dropdown-alt'
import CustomDropdownMultiple from '../../assets/elements/input/custom-dropdown-multiple-alt'

import CustomGuestInfo from '../../assets/controls/custom-guest-info'
import Checkbox from '../../assets/elements/checkbox'
import CustomNumberRange from '../../assets/elements/input/custom-number-range'
import CustomNumber from '../../assets/elements/input/custom-number-alt'
// import CustomDateRange from '../../assets/elements/input/custom-date-range'
import CustomDateRangeAlt from '../../assets/elements/input/custom-date-range-alt'

import CustomText from '../../assets/elements/input/custom-text-alt'
import TreelistSearcher from '../../assets/controls/accordion/treelist-group'
import Notes from '../../assets/controls/accordion/textarea'
import CustomNumberDropdownGroup from '../../assets/elements/input/custom-number-group-dropdown-alt'

//images
import LocationIcon from '../../../images/location.png'
import LocationRadiusIcon from '../../../images/location_radius.png'
import FloorPlanIcon from '../../../images/floor plan.png'
import BuildingIcon from '../../../images/request-icons/building.svg'
import DateRangeIcon from '../../../images/request-icons/calendar.svg'
//constant 
import { MILES } from '../../../constant/dropdown'
import { BUILDING_TYPES } from '../../../constant/building-types'
import { WEIGHT,MAID_SERVICE,PARKING_SLOTS } from '../../../constant/dictionary'

import '../styles/custom-requirements.css'
import RequestHelper from '../../../utils/helper'

const Requirements = ({
  disabled,
  disableCheckProperties,
  buttonMessage,
  isLoading,
  labelActionButton,
  clearLocationField,
  currencyTypes,
  floorPlanTypes,
    amenities,
  fields,
  setLocation,
  setMiles,
  setBudgetMin,
  setBudgetMax,
  setQtyAdults,
  setQtyChildren,
  setIsPets,
  setQtyPets,
  setDateRange,
  setCurrencyError,
  setCurrencyType,
  setBuildingTypes,
  setFloorPlan,
  setNotes,
  setGuestUser,
  setGuestPhone,
  setGuestEmail,
  setIsGuestCheckboxSelected,
  setSelectedDataAmenities,
  setDataKeysAmenities,
  setIsGuestPhoneValidMessage,
  setIsGuestPhoneValidState,
  setGuestUserEmpty,
  setIsExistingUser,
  setSelectedGuestId,
  setClearLocationField,
  setPetDetails,
  action,
  disableSubmit,
  setMaidService,
  setParkingSlots
}) => {
  const {
    location,
    miles, 
    budgetMin,
    budgetMax,
    qtyAdults,
    qtyChildren,
    isPets,
    qtyPets,
    dateRange,
    currencyError,
    currencyType,
    buildingTypes,
    floorPlan,
    notes,
    guestUser,
    isGuestUserEmpty,
    isGuestPhoneValidMessage,
    isGuestPhoneValidState,
    guestPhone,
    guestEmail,
    isExistingUser,
    selectedGuestId,
    isGuestCheckBoxSelected,
    selectedDataAmenities,
    dataKeysAmenities,
    petDetails,
    maidService,
    parkingSlots
  } = fields
  const [localPetDetails, setLocalPetDetails] = useState(petDetails.length > 0 ? petDetails : [])
  const [isParking, setIsParking] = useState(false)
  useEffect(() => {
    if(petDetails.length > 0)
      setLocalPetDetails(petDetails)
  }, [])
  
  useEffect(() => {
    if(!isPets)
    {
      setQtyPets(null)
      setPetDetails([])
      setLocalPetDetails([])
      // setPetType('')
      // setPetWeight('')
    }
  }, [isPets])

  useEffect(() => {
    if(isParking)
      setParkingSlots(1)
    else
      setParkingSlots(null)
  }, [isParking])
  

  useEffect(() => {
    setStructureByAmountOfPets()
  }, [qtyPets])
  useEffect(() => {
    setPetDetails(localPetDetails)
  }, [localPetDetails])
  
  const setStructureByAmountOfPets = debounce(_ => {
    const structure = {
      petType:'',
      petWeight:null,
      petWeightType:{id: 2, name: "lb"},
    }
    let data = []
    let initIndex = 1
    if(petDetails.length > 0 && qtyPets >= petDetails.length)
    {
      data = [... petDetails]
      initIndex = petDetails.length + 1
    }
    if(qtyPets < petDetails.length)
    {
      data = petDetails.filter(item => item.id <= qtyPets)
      initIndex = qtyPets + 1
    }

      for(let i = initIndex; i <= qtyPets; i++)
      {
        data.push({
          id: i,
          ...structure
        })
      }
    setLocalPetDetails(data)
  },600)

  const onChangePetDetail = (id,newValue,field) => {
    try {
      setLocalPetDetails(prev => prev.map(pet=>pet.id === id ? {...pet,[field]: newValue} : pet))
    } catch (error) {
        //console.log('error setting pet details', error)
    }
  }
  
  return (
    <div className='custom-requirements'>
        <div className='custom-requirements__section-left'>
            {/* <CustomDateRange 
            labelFrom='Move In'
            labelTo='Move Out'
            valueFrom={moveIn}
            valueTo={moveOut}
            setValueFrom={setMoveIn}
            setValueTo={setMoveOut}
            labelSize='116px'
            requiredValue={true}
            /> */}
            <div className='custom-requirements__section-left__date'>
              <CustomDateRangeAlt 
                      label='Move-in/Move-out dates'
                      value={dateRange}
                      setValue={setDateRange}
                      inputIcon={DateRangeIcon}
                      // valueFrom={moveIn}
                      // valueTo={moveOut}
                      // setValueFrom={setMoveIn}
                      // setValueTo={setMoveOut}
                      requiredValue={true}
                      isDisabled={disabled}
                      dateFormat={RequestHelper.getLocalDateFormat() }
              />
            </div>
            
            <div className='custom-requirements__section-left__location'>
              <GoogleWrapper>
                <Location
                  inputId='myBasketRequirementsLocation'
                  location={location} 
                  onChange={setLocation} 
                  placeholder='Enter a location'
                  clearField={clearLocationField}
                  setClearField={setClearLocationField}
                  inputIcon={LocationIcon}
                  disable={disabled}
                  label='Location'
                  requiredValue={true}
                />
              </GoogleWrapper>
            </div>
            
            <div className='custom-requirements__section-left__radius'>
              <CustomDropdown
                label='Search Radius'
                inputIcon={LocationRadiusIcon}
                labelSize='116px'
                values={MILES}
                value={miles}
                setValue={setMiles} 
              />
              
            </div>
            
            <div className='custom-requirements__section-left__budget'>
              <CustomNumberRange 
                label='Nightly Budget'
                labelSize='116px'
                isDisabled={disabled}
                valueMax={budgetMax}
                valueMin={budgetMin}
                setValueMax={setBudgetMax}
                setValueMin={setBudgetMin}
              />
            </div>
            
            <div className='custom-requirements__section-left__currency'>
              <CustomDropdown
                label='Currency'
                placeholder='Choose currency'
                labelSize='116px'
                values={currencyTypes}
                valueName='name'
                value={currencyType}
                isEmpty={currencyError}
                setIsEmpty={setCurrencyError}
                isGroup={true}
                setValue={setCurrencyType}
                requiredValue={budgetMax || budgetMin} 
              />
            </div>
            
            <div className='custom-requirements__section-left__floor'>
              {/* <CustomDropdown
                label='Floor plan'
                placeholder={'All'}
                inputIcon={FloorPlanIcon}
                labelSize='116px'
                values={floorPlanTypes}
                value={floorPlan}
                setValue={setFloorPlan} /> */}
                <CustomDropdownMultiple
                  label='Floor plan'
                  requiredValue={true}
                  values={floorPlanTypes}
                  value={floorPlan}
                  onChangeValue={setFloorPlan} 
                  inputIcon={FloorPlanIcon}
                  selectAllText="All"
                  placeholder="Choose Floorplan"
                /> 
            </div>
              
            <div className='custom-requirements__section-left__building-type'>
              <CustomDropdownMultiple
                label='Type of properties'
                requiredValue={true}
                labelSize='113.12px'
                values={BUILDING_TYPES}
                value={buildingTypes}
                onChangeValue={setBuildingTypes} 
                inputIcon={BuildingIcon}
                selectAllText="All"
                placeholder="Choose Building type"
              /> 
            </div>
               
              <div className='custom-requirements__section-left__qty-adults'>
                <CustomNumber
                  isDisabled={disabled}
                  label='Number of adults'
                  min={1}
                  max={20}
                  value={qtyAdults}
                  setValue={setQtyAdults}
                />
              </div>
             
              <div className='custom-requirements__section-left__qty-children'>
                <CustomNumber
                  isDisabled={disabled}
                  label='Number of children'
                  labelSize='116px'
                  min={0}
                  max={20}
                  value={qtyChildren}
                  setValue={setQtyChildren}
                />
              </div>
              <div className='custom-requirements__maid-service'>
                <CustomDropdown
                  label='Maid Service'
                  labelSize='116px'
                  values={MAID_SERVICE}
                  value={maidService}
                  setValue={setMaidService} 
                />
              </div>
              
              <div className='custom-requirements__isParking'>
                <div className='custom-requirements__isParking__check'>
                  <Checkbox
                    id='isParkingCheck'
                    checkValue={isParking}
                    onChange={(id,value)=>setIsParking(value)}
                    label='Parking' />
                </div>
                
                {isParking &&
                  <div className='custom-requirements__isParking__dropdown'>
                    <CustomDropdown
                      label='Amount of parking'
                      labelSize='116px'
                      values={PARKING_SLOTS}
                      value={parkingSlots}
                      setValue={setParkingSlots} 
                    />
                  </div>
                 
                }
              </div>
              
              <div className='custom-requirements__section-left__pets'>
                <div className='custom-requirements__section-left__pets__checkbox'>
                  <Checkbox
                    id='petFriendlyCheck'
                    checkValue={isPets}
                    onChange={(id,value)=>setIsPets(value)}
                    label='Pets' />
                </div>

                {isPets && 
                <div className='custom-requirements__section-left__pets__content'>
                    <div className='custom-requirements__section-left__pets__content__amount'>
                      <CustomNumber
                        requiredValue={true}
                        label='Amount of pets'
                        min={1}
                        max={10}
                        value={qtyPets}
                        setValue={setQtyPets}
                      />
                    </div>
                  <div className='custom-requirements__section-left__pets__content__details'>
                    {petDetails?.map((detail,index) =>
                      <div className='custom-requirements__section-left__pets__content__details__item'>
                              <CustomText 
                                requiredValue={true}
                                label='What kind' 
                                value={detail.petType} 
                                setValue={value => onChangePetDetail(detail.id,value,'petType')} 
                              />
            
                              <CustomNumberDropdownGroup 
                                requiredValue={true}
                                label='Weight'
                                value={detail.petWeight} 
                                setValue={value => onChangePetDetail(detail.id,value,'petWeight')}
                                dropdownValue={detail.petWeightType} 
                                setDropdownValue={value => onChangePetDetail(detail.id,value,'petWeightType')}
                                values={WEIGHT} 
                                min={0.1} max={300} />
                      </div>
                      )
                    }
                  </div>
                  
                </div>}
                  
              </div>
            
              <div className='custom-requirements__section-left__notes'>
                <Notes 
                    label='Notes' 
                    value={notes} 
                    setValue={setNotes}
                  />
              </div>
                  
              <div className='custom-requirements__section-left__amenities'>
                  <TreelistSearcher 
                    data={amenities} 
                    title='Preferred amenities' 
                    selectedData={selectedDataAmenities} 
                    dataKeys={dataKeysAmenities}
                    setDataKeys={setDataKeysAmenities}
                    setSelectedData={setSelectedDataAmenities}
                  />
              </div>
        </div>
        <div className='custom-requirements__section-right'>
            <CustomGuestInfo 
              guestUser={guestUser}
              guestEmail={guestEmail} 
              guestPhone={guestPhone} 
              isPhoneValidMessage={isGuestPhoneValidMessage}
              isPhoneValidState={isGuestPhoneValidState}
              isExistingUser={isExistingUser} 
              isCheckBoxSelected={isGuestCheckBoxSelected}
              selectedGuestId={selectedGuestId}
              setSelectedUser={setGuestUser} 
              setSelectedGuestId={setSelectedGuestId}
              setGuestEmail={setGuestEmail} 
              setGuestPhone={setGuestPhone} 
              setIsPhoneValidMessage={setIsGuestPhoneValidMessage}
              setIsPhoneValidState={setIsGuestPhoneValidState}
              setIsExistingUser={setIsExistingUser} 
              setIsCheckBoxSelected={setIsGuestCheckboxSelected}
              messageLabel='Firstname and lastname are '
              isEmpty={isGuestUserEmpty}
              setIsEmpty={setGuestUserEmpty}
            />
        </div>
        <div className='custom-requirements__section-buttons'>
          <ActionButton action={action} disableSubmit={disableSubmit} disabled={disableCheckProperties || isLoading} >{labelActionButton}</ActionButton>
          <Loading isVisble={isLoading} size={30}/>
          {buttonMessage && <div className='custom-requirements__section-buttons__message'><span>*</span>- Our manager will fit available properties for you.</div>}
        </div>
    </div>
  )
}
function mapStateToProps({ dictionary }) {
  return {
    currencyTypes: dictionary.currencyTypes,
    floorPlanTypes: dictionary.floorPlanTypes,
      amenities: dictionary.amenities,
  };
}

export default connect(mapStateToProps) (Requirements)